export const ui = {
  new_tab: "Open in new tab",
  expand_box: "Expand box",
  collapse_box: "Collapse box",
  collapse: "Collapse",
  expand: "Expand",
  expand_all: "Expand all",
  search: {
    feedback: "Feedback",
    laws_title: "Laws",
    schemata_title: "Schemata & notes",
    no_results: "No results found",
    no_laws: "No laws found for the search query",
    no_schemata_notes: "No schemata / notes found for the search query",
    missing_something: "Missing something? Let us know via {feedback}!",
  },
};
