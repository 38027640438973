export const settings = {
  edit_account: "Manage Account",
  change: "Change",
  abort: "Cancel",
  continue: "Continue",
  email: {
    change_email: "Change Email Address",
    current_email: "Your current email address is:",
    new_email: "Which email address would you like to use in the future?",
    labels: {
      new_email: "New Email Address",
      confirm_email: "Confirm New Email Address",
    },
  },
  username: {
    change_username: "Change Username",
    current_username: "You are currently using the username:",
    new_username: "Which username would you like to use in the future?",
    labels: {
      new_username: "New Username",
    },
  },
  profileImage: {
    delete_profileImage: "Do you want to remove your profile picture?",
    delete: "Remove",
  },
  account: {
    delete_account: "Delete Account",
    explainer_message:
      "If you delete your account, we will irretrievably delete all personalizations you have made to your online law book, such as your markings, references, schemas, etc. We cannot restore this information afterwards.",
    confirm_password: "Enter your password to confirm:",
    last_warning:
      "You are about to permanently delete your account. If you really want to delete it, click on 'Delete Account'. Once you have submitted a request to delete your account, we will send you an email confirming the deletion process. With this, you can reactivate your account within 30 days and cancel the deletion process. After 30 days, the deletion process begins and you will no longer be able to retrieve your added content or information.",
    last_warning_2:
      "Only to the extent necessary to preserve evidence regarding the provision of any paid services of our offer, we store the metadata (but no content) for the duration of the statutory limitation periods.",
  },
  privacy: {}, // TODO
  password: {
    reset_password: "Reset Password",
    redirect: "You will be redirected to the bookshelf in 5 seconds.",
    send_link: "Send Link",
    confirmation:
      "We will send you an email to reset your password to: {email} You have 60 minutes to click on the link contained therein to set a new password. Also check your spam folder.",
    labels: {
      password: "Password",
    },
  },
};
