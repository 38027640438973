export const legal_text = {
  leftColumn: {
    toc: "Inhaltsverzeichnis",
    tooltips: {
      to_toc: "Zum Inhaltsverzeichnis",
    },
  },
  middleColumn: {
    newSchemata: {
      header: "Hier neue Schemata oder Notizen anlegen",
      text: "... durch Klicken auf ein {clipboardIcon} oder {paperIcon} in der Symbolleiste über dem Gesetzestext.",
    },
    existingSchemata: {
      header: "Hier vorhandene Schemata / Notizen anzeigen",
      text: "... durch Klicken auf das {clipboardIcon} oder {paperIcon} im Gesetzestext oder in der Übersichts-Spalte rechts.",
    },
  },
  textEditor: {
    status: {
      autoSave: "Autosave",
      active: "aktiv",
      inactive: "inaktiv",
      abort: "Abrrechen",
      last_edited: "Zuletzt bearbeitet:",
      hide: "Verstecken",
      publish: "Veröffentlichen",
      confirm_unpublish: "Wirklich offline nehmen?",
      confirm_publish: "Wirklich veröffentlichen?",
    },
    buttons: {
      schema: "Schema",
      note: "Notiz",
      link_chain: "Verweis",
      underline: "Unterstreichung",
      marker: "Markieren",
      delete: "Löschen",
      bookmark: "Lesezeichen",
      remove_bookmark: "Lesezeichen entfernen",
      eraser: "Radierer",
      close: "schließen",
    },
    tooltips: {
      close_schema: "Schema schließen",
      schema: "Schema",
      note: "Notiz",
      link_chain: "Verweis auf andere Norm",
      underline: "Unterstreichung",
      marker: "Markierung",
      delete: "Löschen",
      bookmark: "Lesezeichen an Norm",
      eraser: "Radierer",
      edit_schema: "Schema bearbeiten",
      stop_editing: "Bearbeiten beenden",
      only_self_created_can_be_deleted:
        "Nur selbst erstellte Schemata können gelöscht werden.",
    },
    errors: {
      color_could_not_be_changed: "Farbe konnte nicht geändert werden.",
    },
    helper_texts: {
      schema: "Schema anlegen: In Normtext klicken",
      note: "Notiz anlegen: In Normtext klicken.",
      link_chain: "Verweis anlegen: In Normtext klicken.",
      underline: "Unterstreichen: Normtext markieren.",
      mark: "Farbig markieren: Normtext markieren.",
      eraser: "Löschen: Auf Personalisierung klicken.",
      bookmarkAdded: "Lesezeichen hinzugefügt",
      bookmarkRemoved: "Lesezeichen entfernt",
    },
  },
  rightColumn: {
    schemas: {
      title: "Schemata",
      to: "zu",
      no_schemas_for_entry: "Keine Schemata zur Norm vorhanden.",
      no_related_schemas: "Keine verwandten Schemata vorhanden.",
    },
    notes: {
      title: "Notizen",
      to: "zu ",
      to_norm: "zur Norm",
      no_notes_for_entry: "Keine Notizen vorhanden.",
    },
  },
};
