export const settings = {
  edit_account: "Konto verwalten",
  change: "Ändern",
  abort: "Abbrechen",
  continue: "Weiter",
  email: {
    change_email: "E-mail-Adresse ändern",
    current_email: "Deine derzeitige E-Mail-Adresse lautet:",
    new_email: "Welche E-Mail-Adresse möchtest du künftig verwenden?",
    labels: {
      new_email: "Neue E-Mail-Adresse",
      confirm_email: "Neue E-Mail-Adresse wiederholen",
    },
  },
  username: {
    change_username: "Benutzername ändern",
    current_username: "Du verwendest derzeit den Benutzernamen:",
    new_username: "Welchen Benutzernamen möchtest du künftig verwenden?",
    labels: {
      new_username: "Neuer Benutzername",
    },
  },
  profileImage: {
    delete_profileImage: "Willst du dein Profilbild entfernen?",
    delete: "Entfernen",
  },
  account: {
    delete_account: "Konto löschen",
    explainer_message:
      "Wenn du dein Konto löschst, löschen wir alle von dir vorgenommenen Personalisierungen deines Online-Gesetzbuchs, wie etwa deine Markierungen, Verweise, Schemata etc. unwiderruflich. Diese Informationen können wir anschließend nicht wiederherstellen.",
    confirm_password: "Gib zur Bestätigung dein Passwort ein:",
    last_warning:
      "Du bist dabei, dein Konto dauerhaft zu löschen. Wenn du es wirklich löschen möchtest, klicke auf „Konto löschen“. Sobald du eine Anfrage zum Löschen deines Kontos eingereicht hast, schicken wir dir eine Mail mit der Bestätigung des Löschvorgangs. Damit kannst du dein Konto innerhalb von 30 Tagen reaktivieren und den Löschvorgang abbrechen. Nach 30 Tagen beginnt der Löschvorgang und du kannst deine hinzugefügten Inhalte oder Informationen dann nicht mehr zurückerlangen.",
    last_warning_2:
      "Lediglich soweit dies zur Erhaltung von Beweismitteln bezüglich der Bereitstellung etwaiger kostenpflichtiger Dienste unseres Angebotes erforderlich ist speichern wir für die Dauer der gesetzlichen Verjährungsfristen die Metadaten (jedoch keine Inhalte).",
  },
  privacy: {}, // TODO
  password: {
    reset_password: "Password zurücksetzen",
    redirect: "Du wirst in 5 Sekunden zum Bücherregal weitergeleitet.",
    send_link: "Link senden",
    confirmation:
      "Wir senden dir eine Mail zum Zurücksetzen deines Passworts an: {email} Du hast 60 Minuten Zeit, um durch Klicken auf den darin enthaltenen Link ein neues Passwort zu vergeben. Siehe auch in deinem Spam-Postfach nach.",
    labels: {
      password: "Passwort",
    },
  },
};
