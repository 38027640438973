// de -> transform to Datenschutz
export const data_protection = {
  title: "Datenschutzhinweise",
  subtitle: "DS-GVO und mehr!",
  intro_container: {
    title: "Das Wichtigste in Kürze",
    first_paragraph:
      "Auch wenn du es nicht sehen kannst: Bei LexMea gehört die {trustworthy_policy}, die bei deiner Nutzung unserer Services entstehen, genauso zum Service wie ein hochwertiges Online-Angebot selbst.",
    trustworthy_policy:
      "sichere, vertrauens- und respektvolle Verarbeitung der Daten",
    second_paragraph:
      "Um die Sicherheit deiner Daten angemessen bei der Übertragung zu schützen, verwenden wir orientiert am {current_technology} (z.B. DDoS-Schutz, SQL Injection-Protection, Anti-CSRF-Token…).",
    current_technology:
      "aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z.B. SSL/TLS) und gesicherte technische Systeme",
    third_paragraph:
      "Um den Schutz deiner personenbezogenen Daten zu garantieren, gehen wir zudem an zahlreichen Stellen über {high_requirements}. Du findest diese Stellen durch unser LexMea-Datenschutzschild gekennzeichnet. Hier ein Überblick:",
    high_requirements:
      "den bereits hohen europäischen Datenschutzstandard der DS-GVO hinaus",
  },
};
