// en
export const terms_and_conditions = {
  title: "Terms and Conditions",
  subtitle: "Our General Terms and Conditions",
  intro_container: {
    title: "The Essentials in Brief",
    first_paragraph:
      "By registering, a contract for the provision of digital services is concluded. This does not incur {no_cost} for the user.",
    no_cost: "any costs",
    second_paragraph:
      "This is possible by placing legal job advertisements (e.g., from law firms), event notices (e.g., from tutors), and advertisements (e.g., for textbooks and the like) on our website. If we personalize this advertising, we do not pass {no_data_to_advertisers}, but perform the personalization ourselves on our own European servers. For example, we show users who open § 433 BGB textbooks on sales law without our advertising partners knowing who opens § 433 BGB.",
    no_data_to_advertisers: "any data to advertisers",
    third_paragraph:
      "Premium subscriptions can be taken out for the use of additional functions published after the market launch. However, the functions offered at the market launch will also {stay_free}.",
    stay_free: "remain free of charge in the future",
  },
};
