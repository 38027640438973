export const ui = {
  expand_box: "Box ausklappen",
  collapse_box: "Box einklappen",
  collapse: "Einklappen",
  expand: "Ausklappen",
  expand_all: "Alle ausklappen",
  search: {
    feedback: "Feedback",
    laws_title: "Gesetze",
    schemata_title: "Schemata & Notizen",
    no_results: "Keine Ergebnisse gefunden",
    no_laws: "Keine Gesetze zur Suchanfrage",
    no_schemata_notes: "Keine Schemata / Notizen zur Suchanfrage",
    missing_something: "Fehlt dir etwas? Lass es uns via {feedback} wissen!",
  },
};
