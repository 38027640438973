export const vision_page = {
  hero_section: {
    main_title: "Vision",
    slogan: "Your Law in the Future",
  },
  intro_quote:
    "LexMea aims to revolutionize legal work by digitally uniting all the necessary tools in one place.",
  features_section: {
    first_feature: {
      title: "Less analogous",
      disadvantages: "Disadvantages of using analog laws",
      description:
        "Legal work is already almost exclusively digital today. Printed laws will soon be a thing of the past. By using LexMea, the {disadvantages} are eliminated: Lawyers no longer need to purchase, carry, painstakingly reorder, and transfer annotations from heavy law books. This also significantly conserves our natural resources.",
    },
    second_feature: {
      title: "More Connectivity",
      description:
        "LexMea not only frees users from the disadvantages of using analog laws, it also creates significant added value: At the respective norms, both editorially created examination schemes can be viewed and one's own knowledge can be stored. Everything is gathered in one digital place, made searchable, and can be conveniently accessed from anywhere. This ensures better connectivity between norms and knowledge, both in practical case processing and in examination situations. The enormous amounts of knowledge in legal education become manageable with LexMea.",
    },
    third_feature: {
      title: "Full Security",
      data_privacy: "Data protection and data security",
      description:
        "Users can always rely on the highest level of {data_privacy}. LexMea refrains from using large US services (Google Analytics, Facebook Pixel, etc.) and hosts the data on European servers.",
    },
  },
  outro_quote:
    "Lawyers will work differently in the future. All they need is their laptop and LexMea.",
  contact_section: {
    title: "Do you have ideas?",
    first_paragraph: {
      text: "We want to design the perfect tool for your daily work! Do you have {highlighted_text}? Feel free to send them to us at {contact_email} or use our {feedback_form}.",
      highlighted_text: "wishes or suggestions",
      contact_email: "kontakt@lexmea.de",
      feedback_form: "feedback form",
    },
    second_paragraph: {
      text: "Do you want to {participate} and become part of our {team}? We are always looking for interns, legal trainees, content creators, etc. Feel free to send us an informal application with a resume to {contact_email}",
      participate: "get involved",
      contact_email: "bewerbung@lexmea.de",
      team: "team",
    },
  },
};
