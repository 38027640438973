export const bookshelf = {
  login: "log in",
  register: "register",
  login_or_register: "{login} or {register}",
  tooltips: {
    title: "The library is your central overview page.",
    quick_access: "Place the law books you use most frequently here.",
    bookmarks: "Your bookmarked norms from the workspace are collected here.",
    law_books:
      "All laws currently available on LexMea are listed here. We are constantly expanding.",
    content_by_legal_field:
      "We have assigned the norms on LexMea to legal fields. Here, the content created by you (and us) is automatically displayed sorted by legal fields.",
  },
  tabs: {
    laws: "Laws",
    content: "Content",
    chronic: "Chronic",
  },
  laws: {
    keywords: {
      laws: "Laws",
      laws_az: "Laws (A-Z)",
      quick_access: "Quick Access",
      bookmark: "Bookmark",
      bookmarks: "Bookmarks",
      law_books: "Law Books",
    },
    lawTypes: {
      alle: "All",
      de: "Federal Law (DE)",
      eu: "European Law",
      int: "International Law",
    },
    info: {
      more_info:
        "Further down you will already find an overview of the laws available on LexMea {laws_az}.",
      explanation:
        "To store frequently used law books in your {quick_access} and personalize them by color, as well as to view an overview of the norms marked with a {bookmark} in your workspace, ",
    },
  },
  content: {
    keywords: {
      by_legal_field: "by legal fields",
      filter_icon: "Filter Icon",
    },
    info: {
      intro:
        "We have assigned all norms on LexMea to legal fields. {contents} created in the workspace (schemas, notes, etc.) is therefore displayed here, sorted {by_legal_field}.",
      logged_in:
        "Use the {filter_icon} to set which type of content should be displayed to you.",
      no_user: {
        part_2: "You already see {all_schemata}.",
        all_schemata: "all schemas created by LexMea editorial staff",
        part_3: "To see your currently only locally saved entries displayed,",
      },
    },
  },
  chronic: {
    info: {
      logged_in:
        "Content created in the workspace according to standards (schemas, markings, etc.) is displayed here sorted by edit date. Deleted content can be restored from the trash within 30 days.",
      no_user:
        "{action}, to get your content created in the workspace according to standards (schemas, markings, etc.) displayed here sorted by edit date and to be able to restore deleted content from the trash within 30 days.",
    },
    current: {
      heading: "Content by Creation Date",
      subtitle:
        "Here you will find a chronologically sorted list of the content you have created.",
      empty_all: "You haven't created anything yet.",
      empty_type: "You haven't created any {type} yet.",
      no_user:
        "{action}, to view a chronicle of all the content you have created here.",
    },
    deleted: {
      heading: "Trash",
      subtitle:
        "Here you will find a chronologically sorted list of the content you have deleted.",
      empty_all: "You haven't deleted any content yet.",
      empty_type: "You haven't deleted any {type} yet.",
      no_user: "{action}, to view your deleted content here.",
    },
  },
};
