export const faq_page = {
  hero_section: {
    main_title: "F.A.Q.",
    slogan: "Gerne beantworten wir deine Fragen!",
  },
  faq_sections: [
    {
      id: "online-lawbooks",
      title: "...zu Online-Gesetzbüchern",
      items: [
        {
          question:
            "Was sind die Vor- und Nachteile von analogen Gesetzbüchern?",
          answer:
            "Analoge Gesetzbücher sind leider weiterhin die einzig in juristischen Prüfungen zugelassenen Hilfsmittel. Ob ihres Gewichts werden die roten Gesetzessammlungen auch „Ziegelsteine“ genannt. Um ein aktuelles Gesetzbuch zu besitzen, muss dieses vor der Prüfung ohnehin mühsam nachsortiert werden (bei Loseblattsammlungen) oder ein neues gekauft werden (bei gebundenen Ausgaben). Etwaige Personalisierungen müssen - sofern zulässig - ohnehin händisch übertragen werden.",
        },

        {
          question:
            "Was sind die Vor- und Nachteile eines digitalen Gesetzbuchs?",
          answer:
            "Digitale Gesetzbücher sind in juristischen Prüfungen bis dato nicht als Hilfsmittel zugelassen. Sie nehmen jedoch weder Platz noch Gewicht im Rucksack ein und aktualisieren sich automatisch. Die Personalisierungen sind für die Dauer digital gespeichert und können so auch in ferner Zukunft genutzt werden. Der Kern deines juristischen Wissens bleibt somit dauerhaft konserviert.",
        },
        {
          question:
            "Sollte ich ein analoges oder digitales Gesetzbuch verwenden?",
          answer:
            "In der täglichen Arbeit bieten jedoch digitale Gesetzbücher zahlreiche Vorteile. Sie nehmen weder Platz noch Gewicht ein und aktualisieren sich automatisch. Auf LexMea kann das Gesetzbuch zudem dauerhaft digital personalisiert werden und das Wissen über Studium / Einarbeitungsphasen hinaus dauerhaft für spätere Arbeiten gespeichert werden. Da alleine analoge Gesetzbücher in juristischen Prüfungen zugelassen sind, ist es bis dato jedoch auch  unumgänglich, sich kurz vor einer Prüfung ein analoges Gesetzbuch zu kaufen.",
        },
      ],
    },
    {
      id: "web-app",
      title: "…zur Web-App",
      items: [
        {
          question: "Was kann ich auf LexMea machen?",
          answer:
            'LexMea vereint (im „Arbeitsbereich") alles, was dein Jura braucht an einem Ort: Stetig aktualisierte Gesetzestexte und umfangreiche Personalisierungsfunktionen wie Markierungen, Verlinkungen, Bookmarks, eigene Notizen und Schemata sowie redaktionell erstellte Inhalte. All das wird dir zudem auf einer zentralen Übersichtsseite („Bibliothek") zusammengetragen.',
        },

        {
          question: "Was ist eine Web-App?",
          answer:
            "LexMea ist eine Web-App (Webanwendung) - also ein Anwendungsprogramm, das auf einem entfernten Server gespeichert ist und von dir mit jedem beliebigen Endgerät über den Browser ({emphasized}) aufgerufen werden kann. Du musst vor der Nutzung also nicht erst eine separate Datei herunterladen, installieren und lokal speichern.",
          emphasized: "https://lexmea.de",
        },

        {
          question: "Wieso sollte ich mich bei LexMea registrieren?",
          answer:
            "Wenn du dich registrierst, können wir deine Personalisierungen und Inhalte dauerhaft auf LexMea für dich in deinem Account speichern. Dies ist kostenfrei möglich und wird auch stets kostenfrei bleiben. Der Registrierungsprozess dauert nicht länger als 2 Minuten. Klicke hierfür einfach auf das Profil-Icon ganz rechts oben auf der Seite.",
        },
        {
          question: "Welche Gesetze finde ich auf LexMea?",
          answer:
            "Auf LexMea findest du das gesamte examensrelevante Bundesrecht und Europarecht sowie ausgewählte Normen der gängigsten Schwerpunktbereiche.",
        },

        {
          question:
            "Wie aktuell sind die Gesetze und kann ich mich auf sie verlassen?",
          answer:
            "Wir importieren das Bundesrecht direkt vom Bundesministerium der Justiz und dem Bundesamt für Justiz (gesetze-im-internet.de). Das Europarecht importieren wir direkt über die offizielle Website der Europäischen Union ({emphasized}). Die dort abgerufenen Normen stellen stets die aktuell geltende Fassung dar. Den Stand unseres Imports findest du stets unter der jeweiligen Norm angezeigt.",
          emphasized: "https://eur-lex.europa.eu",
        },

        {
          question: "Woher kommen die LexMea-Schemata? ",
          answer:
            "Die LexMea-Schemata werden redaktionell durch das Team Content bei LexMea erstellt. Die Qualität wird durch die beiden CEOs, die ihr Studium beide mit Prädikatsexamen abgeschlossen haben und an verschiedenen Universitäten unterrichten, sichergestellt.",
        },
        {
          question:
            "Kann ich meine eigenen Schemata herunterladen und lokal speichern?",
          answer:
            "Ja, über den Download-Button an jedem Schema sowie in deiner zentralen Übersichtsseite (Bücherregal) kannst du deine Schemata als .pdf-Datei herunterladen und lokal speichern.",
        },
        {
          question: "Werden meine Inhalte gelöscht? ",
          answer:
            "Nein, deine Inhalte bleiben dauerhaft sicher gespeichert - außer du löschst sie eigenhändig in der App. Selbst in diesem Fall bleiben sie noch 14 Tage im Papierkorb zur Wiederherstellung zwischengespeichert, bevor sie endgültig und unwiderruflich gelöscht werden.",
        },
        {
          question: "Wie melde ich Fehler oder Verbesserungsvorschläge? ",
          answer:
            'Wir wollen LexMea kontinuierlich weiterentwickeln und an deine Bedürfnisse anpassen! Wenn dir ein Fehler auffällt oder du Verbesserungsvorschläge hast, sende diese liebend gerne an {emphasized}. Zudem steht dir für Feedback auch unsere Eingabemaske "Feedback geben" zur Verfügung. Diese öffnest du entweder über den Link ganz rechts unten auf der Seite oder eingeloggt hinter deinem Profil-Icon ganz rechts oben auf der Seite.',
          emphasized: "kontakt{'@'}lexmea.de",
        },
        {
          question:
            "Kann ich die Schemata von LexMea weiterverwenden und bearbeiten, ohne Urheberrechte zu verletzen?",
          answer:
            "LexMea-Schemata dürfen zu privaten Zwecken abgerufen, heruntergeladen, lokal gespeichert, kopiert und verändert werden. Darüberhinausgehende Vervielfältigungen oder die sonstige Nutzung oder Verwertung von Dokumenten – insbesondere zu kommerziellen Zwecken – sind nur mit vorangehender schriftlicher Zustimmung LexMeas zulässig. Nutzer dürfen Kopien von redaktionell erstellten Inhalten an einzelne Dritte (etwa Kollegen im Rahmen der Rechts- und Steuerberatung oder Kommilitonen) weitergeben. Die systematische Weitergabe von redaktionell erstellten Inhalten oder deren systematische Zugänglichmachung an Dritte sowie die Nutzung zum Zweck der geschäftsmäßigen Informationsvermittlung (Recherche und Dokumentabruf im Auftrag eines Dritten) sind unzulässig.",
        },
        {
          question: "Wie kann ich meinen Account löschen / wiederherstellen? ",
          answer:
            "Du kannst deinen Account jederzeit in deinen Kontoeinstellungen löschen. Um unbefugte Löschungen zu verhindern, brauchst du hierfür dein Passwort. Wir bestätigen dir deine Löschung anschließend per Mail und schicken dir darin einen 14 Tage gültigen Link zur Wiederherstellung mit. Anschließend werden alle deine Daten unwiderruflich von unseren Servern entfernt.",
        },
      ],
    },
    {
      id: "company",
      title: "…zum Unternehmen",
      items: [
        {
          question: "Ist LexMea kostenlos?",
          answer:
            "Ja, LexMea ist kostenlos und die Basisfunktionen werden dies auch stets bleiben. Für hinzukommende Zusatzfunktionen sind in Zukunft Pro-Accounts geplant.",
        },
        {
          question: "Wie finanziert sich LexMea?",
          answer:
            "LexMea wird sich über Anzeigen (insb. für juristische Job-Angebote) finanzieren. Deine Daten werden dafür nie an Dritte weitergegeben. Die Personalisierung findet ausschließlich auf unseren eigenen, europäischen Servern statt. So zeigen wir z.B. Job-Angebote im Arbeitsrecht neben Normen aus diesem Rechtsgebiet an.",
        },
        {
          question: "Wer ist LexMea?",
          answer:
            "LexMea ist ein Start-up mit Sitz in Berlin. Gegründet wurde es von den beiden Juristen Michael und Tobias Strecker. Beide unterrichten an Universitäten und haben so den Bedarf nach einem tool wie LexMea entdeckt. Einen Überblick über alle Mitarbeiter findest du auf unserer Team-Seite.",
        },
        {
          question: "An wen kann ich mich wenden, wenn ich Fragen habe?",
          answer:
            "Wende dich bei Fragen gerne jederzeit an unseren Service unter {emphasized}. Wir freuen uns auch über Feedback oder Wünsche zur Nutzung LexMeas.",
          emphasized: "kontakt{'@'}lexmea.de",
        },
      ],
    },
    {
      title: "…zum Datenschutz",
      id: "data-protection",
      items: [
        {
          question: "Sind meine Daten bei LexMea sicher?",
          answer:
            "Ja, Datenschutz und Datensicherheit haben bei LexMea höchste Priorität. LexMea führt regelmäßig Backups aller auf der Website gespeicherten Daten auf separaten, europäischen Servern durch. So ist auch im unwahrscheinlichen Fall von Schäden am Hauptserver jederzeit eine Wiederherstellung möglich. Zudem sichern wir unsere Seite dem Stand der Technik entsprechend gegen äußere Angriffe ab (z.B. DDOS-Schutz, Web Access Firewall). Wir führen zudem Penetrationstests (Pen-Test) - also autorisierte, simulierte Angriffe auf unsere Seite - durch, um deren Sicherheit zu bewerten und etwaige Schwachstellen zu identifizieren.",
        },
        {
          question: "Bleiben meine Daten in Europa (von der DSGVO geschützt)?",
          answer:
            "Ja, LexMea verzichtet aus datenschutzrechtlichen Gründen auf die Nutzung von Diensten, die deine Daten in die USA übertragen (wie etwa Google Analytics oder der Facebook-Pixel). Deine Daten speichern wir sicher auf ausschließlich europäischen Servern. Die Integrität dieser Daten gewährleistet ein von uns mit dem Serverbetreiber geschlossener Vertrag zur Auftragsverarbeitung (AV-Vertrag).",
        },
        {
          question: "Werden meine Daten verkauft?",
          answer:
            "Nein, deine Daten werden nie verkauft. Wir finanzieren uns über Anzeigen (etwa für juristische Job-Angebote). Wenn wir diese personalisieren, findet dies ausschließlich auf unseren eigenen, europäischen Servern statt.",
        },
      ],
    },
  ],
};
