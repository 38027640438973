export const team_page = {
  hero_section: {
    main_title: "Unser Team",
    slogan: "Lerne das LexMea-Team kennen!",
  },
  team_members: [
    {
      defaultImageSource: "team/michael-benjamin-strecker.webp",
      alternativeImageSource: "team/michi.webp",
      name: "Michael B. Strecker",
      position: "Gründer, CEO",
      abstract:
        "Ich arbeite daran, dass LexMea all die Funktionen enthält, die du für deine tägliche Arbeit benötigst.",
      detailsText:
        '„Ich hab früher in meinem Dorf Fußball gespielt - allerdings so schlecht, dass ich ins Tor musste. Seitdem fokussier ich mich mehr auf geistige Aktivitäten."',
      detailsBulletPointList: [
        "Studium der Rechtswissenschaften an der LMU München (Dipl.-Jur. Univ., Prädikatsexamen) und der Politikwissenschaften an der HfP München (Dipl. sc. pol. Univ., top 15%)",
        "Wissenschaftlicher Mitarbeiter am Lehrstuhl für Öffentliches Recht und Recht der Digitalisierung, Prof. Dr. T. Wischmeyer (seit 2020)",
        "Referent Themenentwicklung Digitalpolitik in einer Bundes-Parteizentrale (2020-2021)",
        "Jurist mit erstem Staatsexamen („Erstgeprüfter“) im Bundesministerium der Justiz und in der Geschäftsstelle der Datenethikkommission der Bundesregierung (2018-2020)",
        "Lehrtätigkeit an der LMU München, der Universität Bielefeld sowie der Humboldt-Universität zu Berlin",
      ],
    },
    {
      defaultImageSource: "team/tobi-main.webp",
      alternativeImageSource: "team/toby.webp",
      name: "Tobias A. Strecker",
      position: "Gründer, CEO",
      abstract:
        "Ich arbeite daran, dass LexMea dir das juristische Arbeiten erleichtert und dir hilft dein juristisches Wissen besser zu managen.",
      detailsText:
        '"In meiner Jugend habe ich Mofas getunt und so erste Einblicke in das Justizsystem gewonnen."',
      detailsBulletPointList: [
        "Studium der Rechtswissenschaften und Wirtschaftswissenschaften an der Humboldt-Universität zu Berlin und der Universität Tübingen",
        "Lehrbeauftragter der Humboldt-Universität zu Berlin (seit 2012)",
        "Referendariat am Kammergericht Berlin mit Stationen in Stuttgart und New York (2012-2014)",
        "Lecturer an der Hokkaido Universität Japan (2015-2017)",
        "Geschäftsführer des Forschungsinstituts für Anwaltsrecht der Humboldt-Universität zu Berlin (2017-2019)",
        "Rechtsanwalt bei Bodenheimer (seit 2018)",
        "Lecturer und Programme Director English Law & German Law LL.B. & LL.M. am King’s College London (seit 2019)",
      ],
    },
    {
      defaultImageSource: "team/chris-robin-ennen.webp",
      alternativeImageSource: "team/chris.webp",
      name: "Chris-Robin Ennen",
      position: "Gründer, CTO",
      abstract:
        "Als Full-Stack Entwickler und Tech-Lead arbeite ich für dich an allem rund um die Website und manage unsere internen Dienste.",
      detailsText:
        "„Ich kümmer mich gleich drum, nur noch die paar Code-Zeilen hier …”",
      detailsBulletPointList: [
        "Ausbildung zum Fachinformatiker bei der Robert Bosch GmbH",
        "Studium der Medieninformatik an der HdM in Stuttgart",
        "Stipendiat im Haus des Akademischen Vereins Hütte",
      ],
    },
    {
      defaultImageSource: "team/kian-ashnad.webp",
      alternativeImageSource: "team/kian.gif",
      name: "Kian Ashnad",
      position: "Technischer Leiter",
      abstract:
        "Als Tech Lead koordiniere ich die Arbeit des Development-Teams und arbeite daran, dass LexMea stets neue Feature entwickelt.",
      detailsText:
        '„Ich habe viele Hobbys, wie Programmieren, Coding und Softwareentwicklung."',
      detailsBulletPointList: [
        "Arbeitserfahrung in Deutschland, der Schweiz und im Mittleren Osten",
        "Mehr als 7 Jahre Erfahrung in der Software-Entwicklung",
        "Studium der Technischen Informatik",
      ],
    },
    {
      defaultImageSource: "team/clemens-wendt.webp",
      alternativeImageSource: "team/clemens.webp",
      name: "Clemens Wendt",
      position: "Content-Manager",
      abstract:
        "Als Content Manager arbeite ich daran, inhaltlich perfekte aber gleichzeitig möglichst verständliche Inhalte für dich bereitzustellen.",
      detailsText:
        '„Seit meiner Zeit in der Waldorfschule ist mein liebestes Hobby die anthroposophische Bewegungskunst Eurythmie"',
      detailsBulletPointList: [
        "Doppelstudiengang der Rechtswissenschaften an der Humboldt-Universität zu Berlin und am King's College London",
        "Stud. Mitarbeiter bei einer der führenden Kanzleien für internationales Wirtschaftsrecht",
      ],
    },
    {
      defaultImageSource: "team/elahe.webp",
      alternativeImageSource: "team/elahe.gif",
      name: "Elahe Kamandi",
      position: "Marketing-Managerin",
      abstract:
        "Als Marketing Managerin überzeuge ich Neukund:innen von den Vorteilen, die LexMea bietet.",
      detailsText:
        '„In meiner Freizeit webe ich Teppiche. Wir haben mehr Teppiche als Boden."',
      detailsBulletPointList: [
        "Spezialistin für datengesteuertes Marketing und Business Intelligence",
        "Studium Wirtschaftsingenieurwesen",
      ],
    },

    {
      defaultImageSource: "team/alina.webp",
      alternativeImageSource: "team/alina-gif.webp",
      name: "Alina Hamm",
      position: "Buchhalterin",
      abstract:
        "Als Finanzmanagerin arbeite ich an der systematischen Erfassung, Dokumentation und Überwachung der Geldströme bei LexMea.",
      detailsText:
        '„Yoga erhöht die Kraft, Flexibilität, Ausdauer und geistige Klarheit! - Ich dann aber so während der Yogastunde..."',
      detailsBulletPointList: [
        "Ausbildung zur Industriekauffrau und Tätigkeit in der Finanzabteilung eines mittelständischen Unternehmens",
        "2-Fächer-Studium der Wirtschaftswissenschaften und Mathematik an der Carl von Ossietzky Universität Oldenburg",
        "Werkstudentin im Fraunhofer Heinrich-Hertz-Institut",
      ],
    },
    {
      defaultImageSource: "team/robert.webp",
      alternativeImageSource: "team/robert.gif",
      name: "Robert Maina",
      position: "Entwickler",
      abstract:
        "Als Frontend-Entwickler verbessere und erweitere kontinuierlich die Feature der Web-App für dich.",
      detailsText:
        '„Auch wenn ich aus Kenia komme, ist Marathon-Laufen die Hölle. Also war klar: Software-Entwicklung."',
      detailsBulletPointList: [
        "Software-Entwickler",
        "Studium der Informatik",
        "Frontend-Nerd",
      ],
    },
    {
      defaultImageSource: "team/new.webp",
      alternativeImageSource: "team/new.gif",
      name: "Komm ins Team!",
      position: "Job / Ref. / Praktikum",
      abstract:
        "Absolviere dein Pflichtpraktikum oder Referendariat bei LexMea oder unterstütze unser Team dauerhaft.",
      detailsText:
        "Wir freuen uns auf deine formlose Bewerbung mit einem kurzen Lebenslauf per Mail an:<br/><u>start@lexmea.de</u>",
      detailsListTitle: "Werde Teil unseres Teams als (m/w/d):",
      detailsBulletPointList: [
        "Pflichtpraktikant:in",
        "Werkstudent:in",
        "Referendar:in",
        "Freelancer:in",
        "Festangestellte/r Mitarbeiter:in",
      ],
    },
  ],
};
