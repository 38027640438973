export const cookies = {
  title: "Wir verwenden nur funktionale Cookies!",
  close: "schließen",
  list: {
    item_1: "Keine Tracking-Cookies",
    item_2: "Kein Google Analytics",
    item_3: "Kein Facebook-Pixel",
  },
  description: {
    text: "Um unser Angebot für dich dennoch möglichst nutzerfreundlich und effektiv zu gestalten, nutzen wir das {open_source}-Analyse-Tool PostHog, das wir {no_tracking} verwenden und auf {european_servers} hosten. So erhalten wir Zusammenfassungen zur Nutzung einzelner Feature. Deine persönlichen Daten werden nie an Werbepartner weitergegeben, geschweige denn verkauft. Erfahre {data_privacy} mehr zum besonders hohen Datenschutz auf LexMea.",
    open_source: "Open-Source",
    no_tracking: "ohne Tracking-Cookies",
    european_servers: "europäischen Servern",
    data_privacy: "hier",
  },
};
