export const vision_page = {
  hero_section: {
    main_title: "Vision",
    slogan: "Dein Jura in Zukunft",
  },
  intro_quote:
    "LexMea will das juristische Arbeiten revolutionieren, indem es alle dafür benötigten Arbeitsmittel an einem Ort digital vereint.",
  features_section: {
    first_feature: {
      title: "Weniger Analoges",
      disadvantages: "Nachteile der Nutzung analoger Gesetze",
      description:
        "Juristisches Arbeiten findet bereits heute nahezu ausschließlich digital statt. Gedruckte Gesetze werden bald der Vergangenheit angehören. Durch die Nutzung LexMeas fallen die {disadvantages} weg: Juristinnen und Juristen müssen keine schweren Gesetzbücher mehr erwerben, mit sich führen, aufwendig nachsortieren und Kommentierungen übertragen. Damit geht auch eine nicht unerhebliche Schonung unserer natürlichen Ressourcen einher.",
    },
    second_feature: {
      title: "Mehr Verknüpfung",
      description:
        "LexMea befreit die Nutzenden nicht nur von den Nachteilen einer analogen Gesetzesnutzung, es schafft einen erheblichen Mehrwert: An den jeweiligen Normen können sowohl redaktionell erstellte Prüfungsschema eingesehen, als auch das eigene Wissen gespeichert werden. So wird alles an einem digitalen Ort zusammengetragen, durchsuchbar gemacht und kann von überall bequem abgerufen werden. Das sorgt für eine bessere Verknüpfung von Norm und Wissen sowohl bei der Bearbeitung praktischer Fälle als auch in Prüfungssituationen. Die enormen Wissensmassen der juristischen Ausbildung werden durch LexMea handhabbar.",
    },
    third_feature: {
      title: "Volle Sicherheit",
      data_privacy: "Datenschutz und Datensicherheit",
      description:
        "Dabei können sich die Nutzenden stets auf ein höchstmögliches Maß an {data_privacy} verlassen. LexMea verzichtet auf die Nutzung großer US-amerikanischer Dienste (Google Analytics, Facebook Pixel, etc.) und hostet die Daten auf europäischen Servern.",
    },
  },
  outro_quote:
    "Juristinnen und Juristen werden künfitg anders arbeiten. Alles, was sie dafür benötigen, ist ihr Laptop und LexMea.",
  contact_section: {
    title: "Du hast Ideen?",
    first_paragraph: {
      text: "Wir wollen das perfekte Tool für deine tägliche Arbeit entwerfen! Du hast {highlighted_text}? Sende uns liebend gerne am {contact_email} oder nutze unser {feedback_form}.",
      highlighted_text: "Wünsche oder Anregungen",
      contact_email: "kontakt@lexmea.de",
      feedback_form: "Feedback-Formular",
    },
    second_paragraph: {
      text: "Du möchtest {participate} und Teil unseres {team} werden? Wir suchen stets Praktikant:innen, Referendar:innen, Content Creator u.a. Sende uns gerne ein formloses Anschreiben mit Lebenslauf an {contact_email}",
      participate: "selbst mitwirken",
      contact_email: "bewerbung@lexmea.de",
      team: "Teams",
    },
  },
};
