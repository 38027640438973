export const onboardingTour = {
  step_0: {
    title: "Glad you're here!",
    text: "Can we show you the {emphasized} in 1 minute?",
    emphasized: "most important features",
    buttonLeft: "Skip",
    buttonRight: "Start tour",
  },
  step_1: {
    title: "Search",
    text: "Start searching for legal norms or keywords in schemas/notes.",
    buttonLeft: "Back",
    buttonRight: "Next 1/8",
  },
  step_2: {
    title: "Column 1: Overview Boxes",
    text: "Here you will find the {emphasized} related {emphasized2} in column 2 and navigate to other norms or the detailed table of contents.",
    emphasized: "law",
    emphasized2: "overview boxes",
    buttonLeft: "Back",
    buttonRight: "Next 2/8",
  },
  step_3: {
    title: "Column 2: Law",
    text: "Here you will always find your {emphasized} and personalize it.",
    emphasized: "legal norm",
    buttonLeft: "Back",
    buttonRight: "Next 3/8",
  },
  step_4: {
    title: "Toolbox",
    text: "{emphasized} your law book through: schemas, notes, underlinings, markings, references, bookmarks.",
    emphasized: "Personalize",
    buttonLeft: "Back",
    buttonRight: "Next 4/8",
  },
  step_5: {
    title: "Tabs",
    text: "Open {emphasized} side by side in tabs.",
    emphasized: "multiple norms",
    buttonLeft: "Back",
    buttonRight: "Next 5/8",
  },
  step_6: {
    title: "Column 3: Contents",
    text: 'In column 3 you will always find the {emphasized} (schemas and notes) attached to the norm by LexMea or by you. <div class="text-xs italic pt-3">Tip: When creating schemas, use the {emphasized2} for headings and boxes like definitions or structure hints.</div>',
    emphasized: "contents",
    emphasized2: "format templates",
    buttonLeft: "Back",
    buttonRight: "Next 6/8",
  },
  step_7: {
    title: "Column 4: Overview Boxes",
    text: "Here you will find the {emphasized} related {emphasized2} in column 3 and navigate to other schemas or notes.",
    emphasized: "contents",
    emphasized2: "overview boxes",
    buttonLeft: "Back",
    buttonRight: "Next 7/8",
  },
  step_8: {
    title: "Library",
    text: "Navigate from the workspace to the library for a comprehensive {emphasized} of your personalizations and laws.",
    emphasized: "overview",
    buttonLeft: "Back",
    buttonRight: "Get started",
  },
};
