// de
export const terms_and_conditions = {
  title: "AGB",
  subtitle: "Allgemeine Geschäftsbedingungen",
  intro_container: {
    title: "Das Wichtigste in Kürze",
    first_paragraph:
      "Durch die Registrierung kommt ein Vertrag über die Bereitstellung digitaler Dienstleistungen zustande. Dem Nutzer entstehen hierdurch {no_cost}.",
    no_cost: "keine Kosten",
    second_paragraph:
      "Dies ist möglich, indem wir juristische Stellenanzeigen (etwa von Kanzleien), Veranstaltungshinweise (etwa von Repetitorien) sowie Werbeanzeigen (etwa zu Lehrbüchern u. dergl.) auf unserer Website platzieren. Wenn wir diese Werbung individualisieren geben wir {no_data_to_advertisers} weiter, sondern nehmen die Personalisierung selbst auf unseren eigenen, europäischen Servern vor. So zeigen wir beispielsweise Nutzern, die § 433 BGB öffnen, Lehrbücher zum Kaufrecht an, ohne dass unsere Werbepartner wissen, wer § 433 BGB öffnet.",
    no_data_to_advertisers: "keine Daten an Werbepartner",
    third_paragraph:
      "Für die Nutzung von nach Markteinführung veröffentlichten Zusatzfunktionen können Premium-Abonnements abgeschlossen werden. Die bei Markteinführung angebotenen Funktionen werden jedoch auch {stay_free}.",
    stay_free: "in Zukunft stets kostenfrei bleiben",
  },
};
