import type de from "./de";
import { intro_page } from "~/i18n/en/infoPages/introPage";
import { vision_page } from "~/i18n/en/infoPages/visionPage";
import { team_page } from "~/i18n/en/infoPages/teamMembers";
import { faq_page } from "~/i18n/en/infoPages/faq";
import { data_protection as data_privacy } from "~/i18n/en/infoPages/dataProtection";
import { imprint } from "~/i18n/en/infoPages/imprint";
import { terms_and_conditions } from "~/i18n/en/infoPages/termsAndConditions";

import { feedback_form } from "~/i18n/en/feedback_form";
import { onboardingTour } from "~/i18n/en/onboardingTour";
import { cookies } from "~/i18n/en/cookies";

import { ui } from "~/i18n/en/ui";
import { authentication } from "~/i18n/de/authentication";
import { bookshelf } from "~/i18n/en/bookshelf";
import { entries } from "~/i18n/en/entries";
import { legal_text } from "~/i18n/en/legalText";
import { settings } from "~/i18n/en/settings";

const en: typeof de = {
  authentication,
  ui,
  settings,
  library: "Library",
  workspace: "Workspace",
  info_site: "Info Pages",
  misc: "Misc.",
  intro: "Intro",
  tour: "Tour",
  vision: "Vision",
  team: "Team",
  faq: "F.A.Q.",
  terms_and_conditions: "Terms and Conditions",
  data_protection: "Data Protection",
  imprint: "Imprint",
  schemata: "Schemas",
  feedback: "Feedback",
  offline: "You are offline",
  main_search_placeholder: "Search for paragraph, law book, or keyword",
  bookshelf,
  entries,
  feedback_form,
  cookies,
  legal_text,
  onboardingTour,
  info_pages: {
    intro_page,
    vision_page,
    team_page,
    faq_page,
    terms_and_conditions,
    data_privacy,
    imprint,
  },
};

export default en;
