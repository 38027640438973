export const data_protection = {
  title: "Data Protection Information",
  subtitle: "GDPR and more!",
  intro_container: {
    title: "The Essentials in Brief",
    first_paragraph:
      "Even if you can't see it: At LexMea, the {trustworthy_policy} that arises from your use of our services is just as much a part of the service as a high-quality online offering itself.",
    trustworthy_policy:
      "secure, trustworthy, and respectful processing of data",
    second_paragraph:
      "To adequately protect the security of your data during transmission, we use methods based on the {current_technology} (e.g., DDoS protection, SQL injection protection, anti-CSRF tokens…).",
    current_technology:
      "current state-of-the-art encryption methods (e.g., SSL/TLS) and secure technical systems",
    third_paragraph:
      "To guarantee the protection of your personal data, we also go beyond {high_requirements} in many areas. You can find these areas marked by our LexMea data protection shield. Here is an overview:",
    high_requirements:
      "the already high European data protection standard of the GDPR",
  },
};
