export const legal_text = {
  leftColumn: {
    toc: "Table of contents",
    tooltips: {
      to_toc: "To table of contents",
    },
  },
  middleColumn: {
    newSchemata: {
      header: "Create new schemata or notes here",
      text: "... by clicking on a {clipboardIcon} or {paperIcon} in the toolbar above the legal text.",
    },
    existingSchemata: {
      header: "Display existing schemata / notes here",
      text: "... by clicking on the {clipboardIcon} or {paperIcon} in the legal text or in the overview column on the right.",
    },
  },
  textEditor: {
    status: {
      autoSave: "Autosave",
      active: "active",
      inactive: "inactive",
      last_edited: "Last edited:",
      hide: "Hide",
      abort: "Abort",
      publish: "Publish",
      confirm_unpublish: "Are you sure you want to unpublish?",
      confirm_publish: "Are you sure you want to publish?",
    },
    buttons: {
      schema: "Schema",
      note: "Note",
      link_chain: "Reference",
      underline: "Underline",
      marker: "Marker",
      bookmark: "Bookmark",
      remove_bookmark: "Remove bookmark",
      delete: "Delete",
      eraser: "Eraser",
      close: "close",
    },
    tooltips: {
      schema: "Schema",
      close_schema: "Close Schema",
      note: "Note",
      link_chain: "Reference to another norm",
      underline: "Underline",
      delete: "Delete",
      marker: "Marker",
      bookmark: "Bookmark to norm",
      eraser: "Eraser",
      edit_schema: "Edit schema",
      stop_editing: "Stop editing",
      only_self_created_can_be_deleted:
        "Only schemata created by you can be deleted.",
    },
    errors: {
      color_could_not_be_changed: "Color could not be changed.",
    },
    helper_texts: {
      schema: "Create schema: Click in the legal text.",
      note: "Create note: Click in the legal text.",
      link_chain: "Create reference: Click in the legal text.",
      underline: "Underline: Highlight the legal text.",
      mark: "Highlight: Highlight the legal text.",
      eraser: "Delete: Click on personalization.",
      bookmarkAdded: "Bookmark added",
      bookmarkRemoved: "Bookmark removed",
    },
  },
  rightColumn: {
    schemas: {
      title: "Schemata",
      to: "to",
      no_schemas_for_entry: "No schemata available for the norm.",
      no_related_schemas: "No related schemata available.",
    },
    notes: {
      title: "Notes",
      to: "to",
      to_norm: "to norm",
      no_notes_for_entry: "No notes available for the norm.",
    },
  },
};
