export const feedback_form = {
  give_feedback: "Give Feedback",
  close_feedback_modal: "Close Feedback modal",
  can_we_improve: "Can we improve LexMea for you?",
  types_of_feedback: {
    label: "Type of Feedback",
    general: "General Feedback",
    functions: "Feedback on Functions",
    design: "Feedback on Design",
    contents: "Feedback on Contents",
    missing_feature: "Missing Law / Schema / Feature",
  },
  modal: {
    description: "Description",
    add_screenshot: "Add Screenshot (optional)",
    allowed_formats: "Allowed Formats",
    send: "Send",
  },
};
