export const feedback_form = {
  give_feedback: "Feedback geben",
  close_feedback_modal: "Feedback Modal schließen",
  can_we_improve: "Können wir LexMea für dich verbessern?",
  types_of_feedback: {
    label: "Art des Feedbacks",
    general: "Allgemeines Feedback",
    functions: "Feedback zu Funktionen",
    design: "Feedback zum Design",
    contents: "Feedback zu Inhalten",
    missing_feature: "Fehlendes Gesetz / Schema / Feature",
  },
  modal: {
    description: "Beschreibung",
    add_screenshot: "Screenshot hinzufügen (optional)",
    allowed_formats: "Erlaubte Formate",
    send: "Senden",
  },
};
